<template>
  <div class="indexes container">
    <div class="index-table">
      <div class="index-head">
        <h2 class="index-text">In App Purchases List</h2>
        <div class="tabs">
          <ul>
            <li
              v-for="(tab, i) in pyTabs"
              :key="i"
              @click="changeTabs(tab)"
              :class="tab.isActive ? 'active' : ''"
            >
              <i :class="`bx ${tab.icon}`"></i>
              {{ tab.name }}
              <span class="counts">({{ tab.count }})</span>
            </li>
          </ul>
        </div>
      </div>
      <DropDown
        class="dp"
        :selectableItems="selectableGames"
        v-model="selectedGame"
        place-holder="Game select"
        :isLoading="gamesListLoading"
      ></DropDown>
      <AllPurchases
        :selectedGame="selectedGame"
        v-if="pyTabs[0].isActive"
        @buySuccess="buySuccess"
      />
      <AllUserPurchases :selectedGame="selectedGame" v-if="pyTabs[1].isActive" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AllPurchases from "@/components/panelSections/purchases/AllPurchases.vue";
import AllUserPurchases from "@/components/panelSections/purchases/userPurchases/AllUserPurchases.vue";

export default {
  data() {
    return {
      selectableGames: [],
      selectedGame: null,
      gamesListLoading: false,
      pyTabs: [
        {
          name: "Purchases",
          icon: "bxs-purchase-tag",
          isActive: true,
          count: 0,
        },
        {
          name: "My Items",
          icon: "bxs-user-detail",
          isActive: false,
          count: 0,
        },
      ],
      previousTab: {},
    };
  },
  components: {
    AllPurchases,
    AllUserPurchases,
  },
  computed: {
    ...mapGetters(["token"]),
  },
  mounted() {
    // get session tab name and find it and go on that tab
    if (sessionStorage.getItem("last-pr-tab")) {
      this.previousTab = this.pyTabs.find(
        (element) => element.name === sessionStorage.getItem("last-pr-tab")
      );
      this.changeTabs(this.previousTab);
    }
    this.fetchGames();
    this.fetchAllPurchases();
    if (this.token) this.fetchUserPurchases();
  },
  methods: {
    ...mapActions(["getGames", "getAllPurchases", "getUserPurchases"]),
    fetchGames() {
      this.gamesListLoading = true;
      this.getGames()
        .then((res) => {
          this.selectableGames = res.data.result.data;
          this.selectedGame = this.$route.params.id;
        })
        .finally(() => {
          this.gamesListLoading = false;
        });
    },
    fetchAllPurchases() {
      this.getAllPurchases().then((res) => {
        this.pyTabs[0].count = res.data.result.total ? res.data.result.total : 0;
      });
    },
    fetchUserPurchases() {
      this.getUserPurchases().then((res) => {
        this.pyTabs[1].count = res.data.result.total ? res.data.result.total : 0;
      });
    },
    buySuccess() {
      this.fetchUserPurchases();
    },

    changeTabs(item) {
      //change tab from old to new and save in session
      let oldTab = this.pyTabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.pyTabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
      if (this.token) {
        this.handleSaveTab();
      }
    },
    handleSaveTab() {
      //save active tab(object) name to session
      this.previousTab = this.pyTabs.find((element) => element.isActive === true);
      sessionStorage.setItem("last-pr-tab", this.previousTab.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_indexes.scss";
.charge-btn {
  padding: 5px 0;
  border-radius: 5px;
  width: 180px;
  transition: all 0.5s ease;
}
</style>
