<template>
  <li class="go-item">
    <div class="id center">
      <img src="@/assets/images/running-card/card2.jpg" alt="" class="img center" />
    </div>
    <div class="transaction Q-font center">
      <span class="responsive-label">Name</span>
      {{ purchase.name ? purchase.name : "Purchase Item Name" }}
    </div>
    <div class="amount">
      <span class="responsive-label">Price</span>
      <p v-for="(v, n, i) in JSON.parse(purchase.price)" :key="i">{{ v }} {{ n }}</p>
    </div>
    <div class="date center Q-font">
      <span class="responsive-label">Game</span>
      {{ purchase.game ? purchase.game.name : purchase.game_id }}
    </div>
    <div class="actions center">
      <Tooltip text="Purchase Information">
        <button style="background: none" @click="showDetails(purchase)">
          <i class="bx bxs-info-circle bx-tada" style="color: var(--color7)"></i>
        </button>
      </Tooltip>
      <Tooltip text="Buy" v-if="!isBought">
        <button style="background: none" @click="buyItem(purchase)">
          <i class="bx bx-cart-download" style="color: rgb(0, 255, 0)"></i>
        </button>
      </Tooltip>
    </div>
  </li>
</template>

<script>
export default {
  props: ["purchase", "isBought"],
  data() {
    return {};
  },
  components: {},
  methods: {
    showDetails(item) {
      this.$emit("detailItem", item);
    },
    buyItem(item) {
      this.$emit("onByItem", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.go-item {
  align-items: center;
  background: var(--home-bg-lighter);
  border-radius: 0.9375em;
  margin: 0.5em 0;
  padding: 10px;
  display: grid;
  grid-template-columns: 15% 35% 10% 20% 20%;
  transition: all 0.1s linear;
  // cursor: pointer;
  @include md {
    grid-template-columns: none;
    flex: 0 0 47.5%;
    box-shadow: 0.3125em 0.3125em 0.625em black;
    font-size: 14px;
  }
  @include sm {
    font-size: 12px;
    padding: 5px 10px !important;
    flex: 0 0 100%;
  }

  &:hover {
    box-shadow: 0.2125em 0.2125em 0.525em black;
    transform: scale(1.005);
    // box-shadow: -0.625em 0.625em 1.25em black;
    // padding: 8px 15px;
  }
  .img {
    width: 4em;
    height: 4em;
    border-radius: 50%;
    border: 3px solid var(--color7);
    margin-right: 3%;
    @include md {
      margin: auto;
    }
  }
  .bx {
    font-size: 1.875em;
  }
  .name {
    color: #e8eaf2;
    @include sm {
      font-size: 9px;
      margin: 0 2px;
    }
  }
  div {
    color: #8799e0;
  }
  .center {
    display: flex;
    justify-content: center;
    @include md {
      justify-content: space-between;
      font-style: normal !important;
    }
  }
  .transaction {
    font-size: 0.875em;
    font-style: italic;
    @include sm {
      font-size: 6px;
    }
  }
  .date {
    font-size: 0.875em;
    font-style: italic;
    @include sm {
      font-size: 6px;
    }
  }

  .responsive-label {
    display: none;
    color: white;
    @include md {
      display: inline-block;
    }
  }
}
</style>
