<template>
  <div class="payment-details">
    <!-- <li>
      <img src="@/assets/images/running-card/card2.jpg" alt="" class="img" />
    </li> -->
    <li>
      <h2 class="name">{{ item.name }}</h2>
    </li>
    <li>
      <p class="game-name">{{ item.game ? item.game.name : item.game_id }}</p>
    </li>
    <li>
      <p class="description">{{ item.description }}</p>
    </li>
    <button>
      <!-- <Loading v-if="!rTime" class="loading"></Loading> -->
      <div>
        <div v-if="token">
          <span style="margin-right: 5px">Buy For</span>
          <span
            v-for="(v, n, i) in JSON.parse(item.price)"
            :key="i"
            style="margin-right: 5px"
          >
            <span>{{ v }}</span
            ><span style="font-size: 10px">{{ n }}</span>
          </span>
        </div>
        <span v-else>Login to buy</span>
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.payment-details {
  padding: 20px;
  // background: linear-gradient(200deg, var(--color3), var(--home-bg));
  background: url("../../../assets/images/running-card/card3.jpg");
  background-size: cover !important ;
  background-repeat: no-repeat !important;
  background-position: center !important;
  max-height: 80vh;
  overflow: auto;
  z-index: 1;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.669);
    z-index: -1;
  }
  li {
    list-style: none;
    text-align: center;
    .name {
      color: var(--color7);
      text-shadow: 0 0 10px blue;
    }
    .game-name {
      color: white;
    }
    .description {
      color: rgb(203, 203, 203);
      margin: 30px 0;
    }
  }
  button {
    padding: 10px 0;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    background: green;
    color: white;
    &:disabled {
      background: grey;
      opacity: 0.8;
      cursor: unset;
    }
    .loading {
      &::v-deep div {
        padding: 5px 0 !important;
      }
    }
  }
}
</style>
