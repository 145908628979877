<template>
  <div class="table">
    <NoResult
      v-if="!purchases || (!purchases.length && !loading)"
      class="fit-height"
    ></NoResult>
    <div v-else class="table-wrapper">
      <BaseLoading v-if="loading" class="fit-height"></BaseLoading>
      <div class="table-content" v-else>
        <ul>
          <li class="headline">
            <div class="id center">Image</div>
            <div class="transaction center">Name</div>
            <div class="amount">Price</div>
            <div class="date center">Game</div>
            <div class="creditor center">Actions</div>
          </li>
          <PurchaseItem
            v-for="(purchase, i) in purchases"
            :key="i"
            :purchase="purchase"
            :isBought="false"
            @detailItem="showDetails"
            @onByItem="onByItem"
          ></PurchaseItem>
        </ul>
        <Pagination
          :total="total"
          :page="page"
          :lastPage="lastPage"
          :loading="loading"
          @nextPage="changePage"
        ></Pagination>
      </div>
    </div>

    <!-- purchase details modal -->
    <BaseModal
      :dialog="purchaseDetailsDialog"
      @close="purchaseDetailsDialog = false"
      title="Purchase Details"
    >
      <PurchaseDetails :item="itemSelected"></PurchaseDetails>
    </BaseModal>
    <!-- purchase details modal -->

    <!-- buy confirmation modal -->
    <BaseModal
      :dialog="buyDialog"
      @close="buyDialog = false"
      title="Buy Purchase Confirm"
    >
      <ConfirmationModal
        message="Do you want to buy this item ?"
        cancelBtnText="No"
        acceptBtnText="Yes"
        :confirmLoading="buyItemLoading"
        @cancel="buyDialog = false"
        @accept="buyItem"
      ></ConfirmationModal>
    </BaseModal>
    <!-- buy confirmation modal -->
  </div>
</template>

<script>
import PurchaseItem from "./PurchaseItem.vue";
import PurchaseDetails from "./PurchaseDetails.vue";
import { mapActions } from "vuex";
export default {
  props: ["selectedGame"],
  data() {
    return {
      purchases: [],
      page: 1,
      total: 0,
      lastPage: 1,
      loading: false,
      itemSelected: {},
      purchaseDetailsDialog: false,
      buyDialog: false,
      buyItemLoading: false,
    };
  },
  components: {
    PurchaseItem,
    PurchaseDetails,
  },
  watch: {
    selectedGame() {
      this.fetchAllPurchases();
    },
  },
  created() {
    this.fetchAllPurchases();
  },
  methods: {
    ...mapActions(["getAllPurchases", "buyPurchaseItem"]),
    fetchAllPurchases() {
      let params = new URLSearchParams({ page: this.page, per_page: 5 });
      if (this.selectedGame) params.append("game_id", this.selectedGame);
      this.loading = true;
      this.getAllPurchases(params)
        .then((res) => {
          console.log({ res });
          this.purchases = res.data.result.data;
          this.total = res.data.result.total;
          this.lastPage = res.data.result.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDetails(purchase) {
      this.itemSelected = purchase;
      this.purchaseDetailsDialog = true;
    },
    onByItem(purchase) {
      this.itemSelected = purchase;
      this.buyDialog = true;
    },
    buyItem() {
      console.log("buy this item : ", this.itemSelected);
      const payload = { game_item_id: this.itemSelected.id };
      this.buyItemLoading = true;
      this.buyPurchaseItem(payload)
        .then((result) => {
          this.fetchAllPurchases();
          this.$emit("buySuccess");
        })
        .catch((err) => {})
        .finally(() => {
          this.buyItemLoading = false;
          this.buyDialog = false;
        });
    },

    changePage(val) {
      this.page = val;
      this.fetchAllPurchases();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_table.scss";
ul {
  @include md {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .headline {
    grid-template-columns: 15% 35% 10% 20% 20% !important;
    @include md {
      display: none !important;
    }
  }
}
.fit-height {
  height: 60vh;
}
</style>
